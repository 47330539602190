<template>
    <div class="online-event-add-view">
        <online-event-form />
    </div>
</template>

<script>
import OnlineEventForm from '@/modules/online-event/components/OnlineEventForm';
export default {
    name: 'EventAddView',
    components: { OnlineEventForm }
};
</script>

<style scoped>

</style>
