import { render, staticRenderFns } from "./EventAddView.vue?vue&type=template&id=388bf8c9&scoped=true&"
import script from "./EventAddView.vue?vue&type=script&lang=js&"
export * from "./EventAddView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388bf8c9",
  null
  
)

export default component.exports